import Vue from 'vue'
import { store } from '@/state/store'

export default function (component) {
  const MenuDataMapper = function (row, rowProps, handleAction) {
    const { id, name, enabled, description } = row
    return [
      {
        title: () => `${enabled ? 'Disable' : 'Enable'} Feature Toggle`,
        icon: () => 'edit',
        viewBox: '0 0 24 24',
        click: (() => async () => {
          const component = () => import('./FeatureToggleSidebar.vue')
          store.dispatch('app/openSidebarDialog', {
            data: {
              title: `${enabled ? 'Disable' : 'Enable'} Feature Toggle`,
              id,
              name,
              enabled,
              description,
            },
            component,
          })
        })(),
      },
    ]
  }

  return Vue.component('WrappedActions', {
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      handleAction: {
        type: Function,
        default: () => null,
      },
      rowProps: {
        type: Object,
        default: () => ({}),
      },
    },
    render(createElement) {
      return createElement(component, {
        props: {
          row: this.row,
          handleAction: this.handleAction,
          rowProps: this.rowProps,
          menu: new MenuDataMapper(this.row, this.rowProps, this.handleAction),
        },
      })
    },
  })
}
