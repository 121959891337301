<template>
  <v-container fluid>
    <v-layout column sheet>
      <BaseTableWrapper :table-init="tableInit">
        <h1 class="page-header">Feature Toggles</h1>
      </BaseTableWrapper>
    </v-layout>
  </v-container>
</template>
<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import FeatureToggleActionsWrapper from '@/components/FeatureToggleActionsWrapper'
import FeatureTogglesActions from '@/components/FeatureTogglesActions.vue'
import { authComputed } from '@/state/helpers'
const wrappedBaseActions = FeatureToggleActionsWrapper(
  FeatureTogglesActions,
  'feature-toggle',
  ['edit-feature-toggle'],
  'feature-toggles'
)
const columns = [
  {
    _t_id: 'db156d27',
    prop: '/',
    text: 'Actions',
    type: 'actions',
    component: wrappedBaseActions,
    sort: false,
    filter: false,
    detail: false,
    shrinkWidth: true,
  },
  {
    _t_id: '0ffb911f',
    prop: 'name',
    text: 'Name',
    sort: false,
    filter: false,
    type: 'text',
    filterType: 'contains',
    sortProp: 'name',
    defaultSort: false,
  },
  {
    _t_id: 'a2418306',
    prop: 'description',
    text: 'Description',
    sort: false,
    filter: false,
    type: 'text',
  },
  {
    _t_id: '713c1524',
    prop: 'isPublic',
    text: 'Public',
    sort: false,
    filter: false,
    type: 'boolean',
    filterType: 'contains',
    sortProp: 'isPublic',
    computedText: (item) => (item ? 'True' : ''),
    defaultSort: false,
  },
  {
    _t_id: '36a13317',
    prop: 'enabled',
    text: 'Enabled',
    sort: false,
    filter: false,
    computedText: (item) => (item ? 'Enabled' : ''),
    type: 'boolean',
    filterType: 'contains',
    sortProp: 'enabled',
    detail: false,
  },
]
export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'Feature Toggles',
    }
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        detailKeyId: 'featureToggleId',
        tableId: 'feature_toggles_tv_view',
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        addNewEnabled: false,
        addNewHandler: () => this.$router.push({ name: 'feature-toggles.add' }),
        currentPage: 1,
        perPage: 10,
        isDetailed: false,
        columns,
        collection: 'FeatureToggles',
        vuexAction: 'featureToggles/getFeatureToggleTable',
      },
    }
  },
  computed: {
    ...authComputed,
  },
  created() {},
}
</script>
